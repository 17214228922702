export type TabBaRoutesKey = 'home' | 'help' | 'profile'

export type TabBarPathsMap = Record<TabBaRoutesKey, string | string[]>

const baseLoggedPath = `/lugares`
export const makePlacePath = (placeId: string) => `${baseLoggedPath}/${placeId}`

export const makeTabBarDefaultPaths = (placeId: string) => ({
  home: `${makePlacePath(placeId)}/inicio`,
  help: `${makePlacePath(placeId)}/ajuda`,
  profile: `${makePlacePath(placeId)}/perfil`,
})
