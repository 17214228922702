import type { UIMatch } from '@remix-run/react'
import { useMatches } from '@remix-run/react'
import { useCallback } from 'react'

import type { TabBaRoutesKey, TabBarPathsMap } from '~/utils/navigation.utils'

type MatchesData = {
  overrides?: { tabBarPaths: TabBarPathsMap }
}

const useOverrideTabBarPaths = () => {
  const matches = useMatches() as UIMatch<MatchesData>[]

  const overrides = matches.reduce((acc, route) => {
    if (route.data?.overrides) return route
    return acc
  })?.data?.overrides?.tabBarPaths

  /** Overwrites the passed default paths keys with the overrides from a loader. */
  const overridePaths = useCallback(
    (defaultPaths: TabBarPathsMap) => {
      let newPaths = { ...defaultPaths }

      if (overrides) {
        let route: TabBaRoutesKey
        for (route in overrides) {
          newPaths[route] = overrides[route]
        }
      }

      return newPaths
    },
    [overrides],
  )

  return overridePaths
}

export default useOverrideTabBarPaths
