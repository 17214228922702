import { useNavigate, useParams, useSearchParams } from '@remix-run/react'
import { useCallback, useMemo } from 'react'

import useOverrideTabBarPaths from '~/hooks/useOverrideTabBarPaths'
import { makePlacePath, makeTabBarDefaultPaths } from '~/utils/navigation.utils'

const useAppNavigation = (props?: { backRoute?: string }) => {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // Very scarcely, we may want to modify the paths of the TabBar. For example, if we want the home page
  // item go to a different route. We can do that by returning the overrides in some loader.
  const overridePaths = useOverrideTabBarPaths()

  const placeId = params?.placeId ?? ''
  const placePath = makePlacePath(placeId)

  const { appBasePaths } = useMemo(() => {
    const appBasePaths = overridePaths(makeTabBarDefaultPaths(placeId))

    return { appBasePaths }
  }, [placeId, overridePaths])

  const goBack = () => {
    const idx: number =
      typeof window !== 'undefined' && window.history.state?.idx

    if (!idx) {
      if (props?.backRoute) return navigate(props?.backRoute, { replace: true })
      return navigate('/', { replace: true })
    }
    navigate(-1)
  }

  const isAppBasePath = useCallback(
    (pathname: string) => {
      return Object.values(appBasePaths).flat().includes(pathname)
    },
    [appBasePaths],
  )

  const searchParamsFlags = Object.fromEntries(searchParams.entries())

  const setSeachParamsFlags = useCallback(
    (flags: Record<string, string>) => {
      setSearchParams(
        (prev) => {
          Object.entries(flags).forEach(([key, value]) => {
            if (!value) prev.delete(key)
            else prev.set(key, value)
          })
          return prev
        },
        { preventScrollReset: true, replace: true },
      )
    },
    [setSearchParams],
  )

  return {
    placePath,
    appBasePaths,
    goBack,
    isAppBasePath,
    searchParamsFlags,
    setSeachParamsFlags,
  }
}

export default useAppNavigation
